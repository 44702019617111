<script setup>
import { computed, getCurrentInstance } from 'vue'
import store from "@/store/index";

import {
    GET_USER_DATA,
} from "@/store/getters/user";

const props = defineProps({
    links: {
        type: Array,
        default: null
    },
    title: {
        type: String,
        default: null
    }
})

const hoveredClass = "grey lighten-2";

function checkLinkActive(link) {
    // const currentRouteName = router.currentRoute.name
    // const linkRouteName = link.route.name
    // const linkIsActive = currentRouteName === linkRouteName
    // return linkIsActive
    return currentRoute.value.name === link.route.name
}

const instance = getCurrentInstance()
const currentRoute = computed(() => {
    return instance.proxy.$route
})

function linkHasChildren(link) {
    return link.children !== null && link.children !== undefined

}

function isListGroupOpen(links) {
    for (const link of links) {
        const linkMatchesCurrentRoute = link.route.name === currentRoute.value.name
        if (linkMatchesCurrentRoute) {
            return true
        }
    }
    return false
}

function getListGroupValue(val) {
    console.log(val)
}

function showSelectedLink(text) {
    if (text.text == 'Building Applications') {
        if (store.getters[GET_USER_DATA].profile.role_name == 'County Architect' || store.getters[GET_USER_DATA].profile.role_name == 'Structural Engineer' || store.getters[GET_USER_DATA].profile.role_name == 'Physical/Town Planner') {
            return true

        }
        return false

    }

    return true

}

function showChildLink(item){
    if (item.text == "Create Application") {
        if (store.getters[GET_USER_DATA].profile.role_name == 'County Architect' || store.getters[GET_USER_DATA].profile.role_name == 'Structural Engineer') {
            return true

        }
        return false

    }

    if (item.text == "Query Applications") {
        if (store.getters[GET_USER_DATA].profile.role_name == 'County Architect' || store.getters[GET_USER_DATA].profile.role_name == 'Structural Engineer' || store.getters[GET_USER_DATA].profile.role_name == 'Physical/Town Planner') {
            return true

        }
        return false
        
    }

    if (item.text == "My Drafts") {
        if (store.getters[GET_USER_DATA].profile.role_name == 'County Architect' || store.getters[GET_USER_DATA].profile.role_name == 'Structural Engineer') {
            return true

        }
        return false
        
    }

    if (item.text == "Query Enforcement") {
        if(store.getters[GET_USER_DATA].profile.role_name == 'Physical/Town Planner'){
            return true

        }
        return false
        
    }

    return true

}
</script>

<template>
    <v-list nav class="background links px-2 rounded-tl rounded-bl" :style="{ minHeight: '50vh' }">
        <v-subheader v-if="title">{{ props.title }}</v-subheader>
        <template v-for="(link, index) in links">


            <template v-if="linkHasChildren(link)">

                <template v-if="showSelectedLink(link)">
                    <v-list-group :key="index" :prepend-icon="link.icon"
                        :value="isListGroupOpen(link.children)">


                        <template #activator>
                            <v-list-item-content>
                                <v-list-item-title class="text-body-2">{{ link.text }}</v-list-item-title>
                            </v-list-item-content>
                        </template>

                        <v-list-item-group class="ml-6" :value="currentRoute.name">
                            <v-hover v-for="childLink in link.children">
                                <template #default="{ hover }" v-if="showChildLink(childLink)">
                                    <router-link :to="childLink.route" class="text-decoration-none">
                                        <v-list-item active-class="active-list-item" :class="{
                                            'background darken-1': hover,
                                        }" :value="childLink.route.name">
                                            <v-list-item-icon>
                                                <v-icon>{{ childLink.icon }}</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title class="text-body-2">{{ childLink.text
                                                    }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </router-link>
                                </template>
                            </v-hover>
                        </v-list-item-group>
                    </v-list-group>

                </template>

            </template>


            <v-list-item-group v-else :value="currentRoute.name">
                <v-hover>
                    <template #default="{ hover }">
                        <router-link :to="link.route" class="text-decoration-none">
                            <v-list-item active-class="active-list-item primary--text" :class="{
                                'background darken-1': hover,
                            }" :value="link.route.name">
                                <v-list-item-icon>
                                    <v-icon>{{ link.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title class="text-body-2">{{ link.text }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </router-link>
                    </template>
                </v-hover>

            </v-list-item-group>
        </template>
    </v-list>
</template>

<style scoped lang="scss">
.active-list-item::before {
    background-color: var(--v-primary-base);
}
</style>
